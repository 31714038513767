export const CloseIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2539 6L6.34619 18"
      stroke="#042037"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.34619 6L18.2539 18"
      stroke="#042037"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIconHighContrast = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.4748 10.73L11.1183 24.19" stroke="#042037" strokeWidth="1.8" />
    <path d="M11.1183 10.73L24.4748 24.19" stroke="#042037" strokeWidth="1.8" />
    <path d="M23.7539 11.47L11.8462 23.47" stroke="white" />
    <path d="M11.8462 11.47L23.7539 23.47" stroke="white" />
  </svg>
);

export const RightArrowIcon = ({ fill = '#FFF', ...props }) => (
  <svg
    width="7"
    height="13"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.48742 0.702682L6.82267 6.07041C7.05813 6.30732 7.06009 6.68944 6.82267 6.92831L1.48742 12.296C1.2516 12.5333 0.871505 12.5355 0.634079 12.2967L0.206389 11.8664C-0.0290797 11.6295 -0.0275584 11.2439 0.207018 11.0079L4.26124 6.92895C4.49706 6.69169 4.49582 6.30578 4.26124 6.06978L0.207018 1.99087C-0.0287981 1.75362 -0.0310377 1.37121 0.206389 1.13234L0.634079 0.702049C0.869547 0.465147 1.25284 0.466677 1.48742 0.702682Z"
      fill={fill}
    />
  </svg>
);

export const LeftArrowIcon = ({ fill = '#000', ...props }) => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6668 0.45166L6.8418 1.62666L3.02513 5.45166L6.8418 9.27666L5.6668 10.4517L0.666797 5.45166L5.6668 0.45166Z"
      fill={fill}
      fillOpacity="0.87"
    />
  </svg>
);

export const DownArrowIcon = ({ fill = '#040404', ...props }) => (
  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.230121 0.892705C0.302808 0.821033 0.389179 0.764166 0.48428 0.725364C0.579382 0.686561 0.681344 0.666586 0.784318 0.666586C0.887292 0.666586 0.989252 0.686561 1.08435 0.725364C1.17946 0.764166 1.26583 0.821033 1.33851 0.892705L5.99963 5.4728L10.6621 0.892706C10.809 0.748279 11.0084 0.667141 11.2162 0.667141C11.4241 0.667141 11.6235 0.74828 11.7704 0.892706C11.9174 1.03713 12 1.23302 12 1.43727C12 1.64152 11.9174 1.83741 11.7704 1.98183L6.55448 7.10713C6.48179 7.1788 6.39542 7.23567 6.30032 7.27447C6.20522 7.31328 6.10326 7.33325 6.00028 7.33325C5.89731 7.33325 5.79535 7.31328 5.70025 7.27447C5.60514 7.23567 5.51877 7.1788 5.44609 7.10713L0.230121 1.98183C0.157181 1.91041 0.0993075 1.82554 0.0598178 1.73209C0.020329 1.63864 5.06597e-07 1.53845 5.15443e-07 1.43727C5.24289e-07 1.33608 0.0203291 1.2359 0.0598179 1.14245C0.0993076 1.049 0.157181 0.964129 0.230121 0.892705Z"
      fill={fill}
    />
  </svg>
);

export const PriceTagIcon = ({ fill = '#FFB400', ...props }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="rebate eligible tag"
    role="img"
    focusable="false"
    {...props}
  >
    <path
      d="M14.1679 9.58208L9.08917 14.6608C8.9576 14.7925 8.80135 14.897 8.62937 14.9683C8.45739 15.0396 8.27305 15.0763 8.08687 15.0763C7.9007 15.0763 7.71636 15.0396 7.54438 14.9683C7.3724 14.897 7.21615 14.7925 7.08458 14.6608L1 8.58333V1.5H8.08333L14.1679 7.58458C14.4318 7.85001 14.5799 8.20907 14.5799 8.58333C14.5799 8.9576 14.4318 9.31665 14.1679 9.58208Z"
      fill={fill}
    />
    <path
      d="M4.54167 5.04167H4.54875M14.1679 9.58208L9.08917 14.6608C8.9576 14.7925 8.80135 14.897 8.62937 14.9683C8.45739 15.0396 8.27305 15.0763 8.08687 15.0763C7.9007 15.0763 7.71636 15.0396 7.54438 14.9683C7.3724 14.897 7.21615 14.7925 7.08458 14.6608L1 8.58333V1.5H8.08333L14.1679 7.58458C14.4318 7.85001 14.5799 8.20907 14.5799 8.58333C14.5799 8.9576 14.4318 9.31665 14.1679 9.58208Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="4" cy="4.5" r="1" fill="white" />
  </svg>
);

export const StarIconFilled = ({ ...props }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8225 8L9 2L7.1775 8H1.5L6.135 11.3075L4.3725 17L9 13.4825L13.635 17L11.8725 11.3075L16.5 8H10.8225Z"
      fill="#FFB400"
    />
  </svg>
);

export const UpArrowsIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6344 12.695C14.4002 12.8823 14.0826 12.9875 13.7515 12.9875C13.4204 12.9875 13.1028 12.8823 12.8687 12.695L7.50781 8.40598L2.14695 12.695C2.03176 12.7904 1.89397 12.8665 1.74162 12.9189C1.58926 12.9713 1.4254 12.9988 1.2596 13C1.09379 13.0011 0.929357 12.9758 0.77589 12.9256C0.622424 12.8754 0.482999 12.8012 0.365751 12.7074C0.248504 12.6136 0.155779 12.502 0.0929908 12.3792C0.0302028 12.2565 -0.00139149 12.1249 4.85493e-05 11.9922C0.00148954 11.8596 0.0359391 11.7285 0.101384 11.6066C0.16683 11.4847 0.26196 11.3745 0.381228 11.2823L6.62495 6.28695C6.85912 6.09965 7.17668 5.99443 7.50781 5.99443C7.83893 5.99443 8.15649 6.09965 8.39067 6.28695L14.6344 11.2823C14.8685 11.4697 15 11.7237 15 11.9887C15 12.2536 14.8685 12.5076 14.6344 12.695ZM14.6344 6.70056C14.4002 6.88786 14.0826 6.99308 13.7515 6.99308C13.4204 6.99308 13.1028 6.88786 12.8687 6.70056L7.50781 2.41155L2.14695 6.70056C1.91144 6.88255 1.596 6.98325 1.26858 6.98098C0.941168 6.9787 0.627967 6.87363 0.39644 6.68839C0.164913 6.50316 0.0335831 6.25258 0.0307373 5.99062C0.0278924 5.72867 0.153758 5.4763 0.381228 5.28788L6.62494 0.292514C6.85912 0.105217 7.17668 -2.69738e-07 7.50781 -2.98686e-07C7.83893 -3.27634e-07 8.15649 0.105217 8.39067 0.292513L14.6344 5.28787C14.8685 5.47523 15 5.7293 15 5.99422C15 6.25914 14.8685 6.51321 14.6344 6.70056Z"
      fill="#E4E4E4"
    />
  </svg>
);

export const CircleCloseIcon = ({ color = '#000' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 330 330" fill={color}>
    <g>
      <path
        d="M165,0C120.926,0,79.492,17.163,48.328,48.327c-64.334,64.333-64.334,169.011-0.002,233.345
		C79.49,312.837,120.926,330,165,330c44.072,0,85.508-17.163,116.672-48.328c64.334-64.334,64.334-169.012,0-233.345
		C250.508,17.163,209.072,0,165,0z M239.246,239.245c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
		L165,186.213l-53.033,53.033c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
		c-5.859-5.857-5.859-15.355,0-21.213L143.787,165l-53.033-53.033c-5.859-5.857-5.859-15.355,0-21.213
		c5.857-5.857,15.355-5.857,21.213,0L165,143.787l53.031-53.033c5.857-5.857,15.355-5.857,21.213,0
		c5.859,5.857,5.859,15.355,0,21.213L186.213,165l53.033,53.032C245.104,223.89,245.104,233.388,239.246,239.245z"
      />
    </g>
  </svg>
);

export const MinusIcon = ({ fill = '#FFF' }) => (
  <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 0V0.0309136C5.25 0 5 -7.45058e-09 6.75 0.0118537C7.16421 0.0118537 6.75 0.0118537 6.75 0.0384777V0.0118537L11.25 0C11.6642 0 12 0.335789 12 0.749999C12 1.16421 11.6642 1.5 11.25 1.5H6.75C6.75 1.5 6.75 1.5 5.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.749999C0 0.335789 0.335786 0 0.75 0H5.25Z"
      fill={fill}
    />
  </svg>
);

export const PlusIcon = ({ fill = '#FFF' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 5.25V0.75C5.25 0.335786 5.58579 0 6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25L11.25 5.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75L6.75 6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25Z"
      fill={fill}
    />
  </svg>
);

export const ExternalLinkIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 10.25V14.75C14 15.1478 13.842 15.5294 13.5607 15.8107C13.2794 16.092 12.8978 16.25 12.5 16.25H4.25C3.85218 16.25 3.47064 16.092 3.18934 15.8107C2.90804 15.5294 2.75 15.1478 2.75 14.75V6.5C2.75 6.10218 2.90804 5.72064 3.18934 5.43934C3.47064 5.15804 3.85218 5 4.25 5H8.75"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.75 2.75H16.25V7.25" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 11L16.25 2.75" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const StarIconEmpty = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 7.43L11.1075 6.965L9 2L6.8925 6.9725L1.5 7.43L5.595 10.9775L4.365 16.25L9 13.4525L13.635 16.25L12.4125 10.9775L16.5 7.43ZM9 12.05L6.18 13.7525L6.93 10.5425L4.44 8.3825L7.725 8.0975L9 5.075L10.2825 8.105L13.5675 8.39L11.0775 10.55L11.8275 13.76L9 12.05Z"
      fill="#B0B0B0"
    />
  </svg>
);

export const StarIconHalfFilled = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 5.43L10.1075 4.965L8 0L5.8925 4.9725L0.5 5.43L4.595 8.9775L3.365 14.25L8 11.4525L12.635 14.25L11.4125 8.9775L15.5 5.43ZM8 10.05V3.075L9.2825 6.105L12.5675 6.39L10.0775 8.55L10.8275 11.76L8 10.05Z"
      fill="#FFB400"
    />
  </svg>
);

export const UpArrowIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    {...props}
  >
    <path
      d="M5 11.0001L6.0575 12.0576L9.5 8.62262L12.9425 12.0576L14 11.0001L9.5 6.50012L5 11.0001Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="#031A2D" />
  </svg>
);

export const PlusCircleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#98A2B3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinusCircleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#98A2B3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckboxIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.32422" y="0.5" width="23" height="23" rx="3.5" fill="#F9F5FF" />
    <rect x="1.32422" y="0.5" width="23" height="23" rx="3.5" stroke="#02B198" />
    <path
      d="M18.8242 7.5L10.5742 15.75L6.82422 12"
      stroke="#02B198"
      strokeWidth="1.6666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 14.75C11.5637 14.75 14.25 12.0637 14.25 8.75C14.25 5.43629 11.5637 2.75 8.25 2.75C4.93629 2.75 2.25 5.43629 2.25 8.75C2.25 12.0637 4.93629 14.75 8.25 14.75Z"
      stroke="#A3A7A9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 16.25L12.4875 12.9875"
      stroke="#A3A7A9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FlashIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8331 1.66675L3.41094 10.5733C3.12026 10.9221 2.97492 11.0965 2.9727 11.2438C2.97077 11.3718 3.02783 11.4937 3.12744 11.5742C3.24201 11.6667 3.46904 11.6667 3.92308 11.6667H9.99972L9.16639 18.3334L16.5885 9.42687C16.8792 9.07806 17.0245 8.90366 17.0267 8.75636C17.0287 8.62832 16.9716 8.50649 16.872 8.426C16.7574 8.33342 16.5304 8.33342 16.0764 8.33342H9.99972L10.8331 1.66675Z"
      stroke="#02B198"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 17.5001V11.3334C7.5 10.8667 7.5 10.6334 7.59083 10.4551C7.67072 10.2983 7.79821 10.1708 7.95501 10.0909C8.13327 10.0001 8.36662 10.0001 8.83333 10.0001H11.1667C11.6334 10.0001 11.8667 10.0001 12.045 10.0909C12.2018 10.1708 12.3293 10.2983 12.4092 10.4551C12.5 10.6334 12.5 10.8667 12.5 11.3334V17.5001M9.18141 2.30345L3.52949 6.69939C3.15168 6.99324 2.96278 7.14017 2.82669 7.32417C2.70614 7.48716 2.61633 7.67077 2.56169 7.866C2.5 8.08639 2.5 8.3257 2.5 8.80433V14.8334C2.5 15.7669 2.5 16.2336 2.68166 16.5901C2.84144 16.9037 3.09641 17.1587 3.41002 17.3185C3.76654 17.5001 4.23325 17.5001 5.16667 17.5001H14.8333C15.7668 17.5001 16.2335 17.5001 16.59 17.3185C16.9036 17.1587 17.1586 16.9037 17.3183 16.5901C17.5 16.2336 17.5 15.7669 17.5 14.8334V8.80433C17.5 8.3257 17.5 8.08639 17.4383 7.866C17.3837 7.67077 17.2939 7.48716 17.1733 7.32417C17.0372 7.14017 16.8483 6.99324 16.4705 6.69939L10.8186 2.30345C10.5258 2.07574 10.3794 1.96189 10.2178 1.91812C10.0752 1.87951 9.92484 1.87951 9.78221 1.91812C9.62057 1.96189 9.47418 2.07574 9.18141 2.30345Z"
      stroke="#02B198"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8337 8.33333V5.16667C15.8337 4.23325 15.8337 3.76654 15.652 3.41002C15.4922 3.09641 15.2372 2.84144 14.9236 2.68166C14.5671 2.5 14.1004 2.5 13.167 2.5H6.83366C5.90024 2.5 5.43353 2.5 5.07701 2.68166C4.7634 2.84144 4.50844 3.09641 4.34865 3.41002C4.16699 3.76654 4.16699 4.23325 4.16699 5.16667V8.33333M4.16699 7.5H1.66699V6.66667M15.8337 7.5H18.3337V6.66667M5.00033 11.25H5.00866M15.0003 11.25H15.0087M5.66699 8.33333H14.3337C15.7338 8.33333 16.4339 8.33333 16.9686 8.60582C17.439 8.8455 17.8215 9.22795 18.0612 9.69836C18.3337 10.2331 18.3337 10.9332 18.3337 12.3333V15C18.3337 15.7766 18.3337 16.1649 18.2068 16.4711C18.0376 16.8795 17.7132 17.204 17.3048 17.3731C16.9985 17.5 16.6102 17.5 15.8337 17.5H15.3337C15.024 17.5 14.8692 17.5 14.7396 17.4795C14.0264 17.3665 13.4671 16.8072 13.3542 16.0941C13.3337 15.9645 13.3337 15.8097 13.3337 15.5C13.3337 15.4226 13.3337 15.3839 13.3285 15.3515C13.3003 15.1732 13.1605 15.0334 12.9822 15.0051C12.9498 15 12.9111 15 12.8337 15H7.16699C7.08958 15 7.05087 15 7.01848 15.0051C6.84019 15.0334 6.70036 15.1732 6.67212 15.3515C6.66699 15.3839 6.66699 15.4226 6.66699 15.5C6.66699 15.8097 6.66699 15.9645 6.64647 16.0941C6.53352 16.8072 5.9742 17.3665 5.26105 17.4795C5.1315 17.5 4.97666 17.5 4.66699 17.5H4.16699C3.39042 17.5 3.00214 17.5 2.69585 17.3731C2.28747 17.204 1.96302 16.8795 1.79386 16.4711C1.66699 16.1649 1.66699 15.7766 1.66699 15V12.3333C1.66699 10.9332 1.66699 10.2331 1.93948 9.69836C2.17916 9.22795 2.56161 8.8455 3.03202 8.60582C3.5668 8.33333 4.26686 8.33333 5.66699 8.33333ZM5.41699 11.25C5.41699 11.4801 5.23044 11.6667 5.00033 11.6667C4.77021 11.6667 4.58366 11.4801 4.58366 11.25C4.58366 11.0199 4.77021 10.8333 5.00033 10.8333C5.23044 10.8333 5.41699 11.0199 5.41699 11.25ZM15.417 11.25C15.417 11.4801 15.2304 11.6667 15.0003 11.6667C14.7702 11.6667 14.5837 11.4801 14.5837 11.25C14.5837 11.0199 14.7702 10.8333 15.0003 10.8333C15.2304 10.8333 15.417 11.0199 15.417 11.25Z"
      stroke="#02B198"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HeartIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99462 4.27985C8.32849 2.332 5.55011 1.80804 3.46257 3.59168C1.37503 5.37532 1.08113 8.35748 2.72049 10.467C4.08351 12.2209 8.20847 15.9201 9.56041 17.1174C9.71166 17.2513 9.78729 17.3183 9.8755 17.3446C9.9525 17.3676 10.0367 17.3676 10.1137 17.3446C10.2019 17.3183 10.2776 17.2513 10.4288 17.1174C11.7808 15.9201 15.9057 12.2209 17.2687 10.467C18.9081 8.35748 18.6501 5.35656 16.5267 3.59168C14.4032 1.8268 11.6607 2.332 9.99462 4.27985Z"
      stroke="#02B198"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShoppingCartIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_678_24749)">
      <path
        d="M4.16711 11.6667H15.1135C15.9576 11.6667 16.3796 11.6667 16.7155 11.5094C17.0115 11.3708 17.2601 11.1482 17.4306 10.8694C17.6241 10.5529 17.6707 10.1334 17.7639 9.29457L18.2514 4.90704C18.2799 4.65082 18.2941 4.52271 18.2529 4.42355C18.2167 4.33646 18.1521 4.26422 18.0695 4.21865C17.9755 4.16675 17.8466 4.16675 17.5888 4.16675H3.75044M1.66699 1.66675H2.70736C2.92788 1.66675 3.03814 1.66675 3.1244 1.70869C3.20034 1.7456 3.26327 1.80473 3.30486 1.87822C3.35209 1.96169 3.35897 2.07174 3.37273 2.29183L4.12792 14.375C4.14168 14.5951 4.14856 14.7051 4.19579 14.7886C4.23738 14.8621 4.30031 14.9212 4.37625 14.9581C4.46251 15.0001 4.57277 15.0001 4.79329 15.0001H15.8337M6.25033 17.9167H6.25866M13.7503 17.9167H13.7587M6.66699 17.9167C6.66699 18.1469 6.48044 18.3334 6.25033 18.3334C6.02021 18.3334 5.83366 18.1469 5.83366 17.9167C5.83366 17.6866 6.02021 17.5001 6.25033 17.5001C6.48044 17.5001 6.66699 17.6866 6.66699 17.9167ZM14.167 17.9167C14.167 18.1469 13.9804 18.3334 13.7503 18.3334C13.5202 18.3334 13.3337 18.1469 13.3337 17.9167C13.3337 17.6866 13.5202 17.5001 13.7503 17.5001C13.9804 17.5001 14.167 17.6866 14.167 17.9167Z"
        stroke="#02B198"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_678_24749">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InfoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0003 13.3334V10.0001M10.0003 6.66675H10.0087M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
      stroke="#02B198"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
